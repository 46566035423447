import React from 'react';

const FilesContext = React.createContext({
    files: [],
    contract: null,
    owner: '',
    appProfits: '0',
    previewModal: false,
    mintUploadProgress: 0,
    preview: '',
    ratingModalSrc: {},
    ratingModalState: false,
    buyers: [],
    transactionLoading: false,
    uploadingProgress: false,
    activities: [],
    loadContract: () => {},
    loadFiles: () => {},
    loadFileBuyers: () => {},
    loadAppOwner: () => {},
    setTransactionLoading: () => {},
    setPreviewSrc: () => {},
    setPreviewModal: () => {},
    loadMintUploadProgress: () => {},
    loadAppProfits: () => {},
    setUploadingProgress: () => {},
    loadActivities: () => {},
    setRatingModalState: () => {},
    setRatingModalSrc: () => {},
});

export default FilesContext;
