import React, { useState, useEffect } from 'react';
import { Link } from 'react-router-dom';
import { BiLinkAlt } from 'react-icons/bi';
import { MdComputer } from 'react-icons/md';
import { BsImages } from 'react-icons/bs';
import useFiles from '../../hooks/useFiles';
import { Jazzicon } from '@ukstv/jazzicon-react';
import { appSettings } from '../../helpers/settings';
import Viewer from 'react-viewer';
import { AvgRating } from './RatingDisplay';
import { FaShoppingCart } from 'react-icons/fa';

function FileBox({ id, metadata, price, reviews }) {
    const { contract, loadFileBuyers, files } = useFiles();
    const [fileBuyers, setFileBuyers] = useState(null);
    const images = metadata?.screenshots?.map((img) => ({ src: img }));
    const [isOpen, setIsOpen] = useState(false);

    /* ------------------------------------------------ */
    //      CHECK IF CONNECTED ACCOUNT HAS ACCESS
    /* ------------------------------------------------ */
    useEffect(() => {
        let cancelled = false;
        if (contract && files.length > 0) {
            async function getFileBuyers() {
                const subs = await loadFileBuyers(contract, Number(id));
                const formattedSubs = subs.map((subscriber) => subscriber[0]);
                setFileBuyers([...new Set(formattedSubs)]);
            }
            if (!cancelled) {
                getFileBuyers();
            }
        }

        // CLEANUP ----------------------------
        return () => (cancelled = true);
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [contract, files]);

    return (
        <div data-aos='fade-up'>
            <div className='movie-box w-100'>
                <div className='movie-box-poster-holder'>
                    <Link to={`/files/${id}`}>
                        <BiLinkAlt className='play-icon text-white z-index-20' size='2.5rem' />
                    </Link>
                    <div className='movie-box-poster' style={{ backgroundImage: `url(${metadata.thumbnail})` }}></div>
                    <div className='movie-box-cta w-100 pt-3'>
                        <button
                            className='btn btn-primary btn-sm w-100 py-2'
                            style={{ borderRadius: '0.5rem' }}
                            onClick={() => {
                                setIsOpen(true);
                            }}
                        >
                            <BsImages className='me-2' />
                            View Screenshots
                        </button>
                    </div>
                </div>

                <div className='d-flex align-items-center justify-content-between mb-1'>
                    <h6 className='mb-0 title'>
                        <Link className='text-reset' to={`/files/${id}`}>
                            {metadata.title}
                        </Link>
                    </h6>
                    <div className='ms-2'>
                        <div className='text-xxs badge fw-normal bg-secondary'>
                            <FaShoppingCart size='1.2rem' style={{ transform: 'translateY(-2px)' }} /> {price} {appSettings.currency}
                        </div>
                    </div>
                </div>

                <div className='d-flex align-items-center info justify-content-between'>
                    <div className='small my-1 text-muted me-3 d-flex align-items-center'>
                        {metadata.category}
                        {fileBuyers && (
                            <ul className='list-unstyled avatars avatars-sm avatars-with-transition mb-0 ms-2'>
                                {fileBuyers.slice(0, 4).map((buyer, i) => {
                                    return (
                                        <li className='avatar avatar-md2' key={i}>
                                            <div style={{ width: '25px', height: '25px' }}>
                                                <Jazzicon address={buyer || ''} />
                                            </div>
                                        </li>
                                    );
                                })}
                            </ul>
                        )}
                    </div>
                    <span className='text-sm ms-2'>
                        <MdComputer className='text-warning me-1' size='1.2rem' /> {metadata.compatibility}
                    </span>
                </div>

                <div className='mb-1'>
                    <AvgRating reviews={reviews} />
                </div>

                {fileBuyers && (
                    <ul className='list-unstyled avatars avatars-sm mb-0'>
                        {fileBuyers.slice(0, 3).map((buyer, i) => {
                            return (
                                <li className='avatar avatar-md2' key={i}>
                                    <div style={{ width: '25px', height: '25px' }}>
                                        <Jazzicon address={buyer || ''} />
                                    </div>
                                </li>
                            );
                        })}
                    </ul>
                )}
            </div>

            {isOpen && (
                <Viewer
                    visible={isOpen}
                    onClose={() => {
                        setIsOpen(false);
                    }}
                    images={images}
                />
            )}
        </div>
    );
}

export default FileBox;
