/* -----------------------------------------------------------------------------------------

    THSI FILE CONTAINS SOME STATIC DATA TO FILL SOME DIFFERENT COMPONENTS

----------------------------------------------------------------------------------------- */

import React from 'react';

/* FAQS LIST - ADD OR DELETE FROM THEM AS YOU NEED */
export const FAQS = [
    {
        question: 'Who are we?',
        answer: (
            <div className='text-muted'>
                <p className='mb-2'>
                    We are a team of Web3 developers, who are implementing what will be the future of the internet, blockchain and decentralization.
                    This project allows each user to securely sell their files, directly from their PC or smartphone, and without third parties.
                </p>
            </div>
        ),
    },
    {
        question: 'DIFI Details and Links',
        answer: (
            <div className='text-muted'>
                <p className='mb-2'>
                    <strong className='text-white'>HBC Contract</strong> – <a href='https://explorer.hashbit.org/address/0x41153dB20A9A4DDf2BFC814b59EE70164EbF712f'>0x41153dB20A9A4DDf2BFC814b59EE70164EbF712f</a>
                </p>
                <p className='mb-2'>
                    <strong className='text-white'>Max/Total Supply</strong> – 10,000,000 DIFI,
                    and asset pipeline for blockchains.
                </p>
                <p className='mb-2'>
                    <strong className='text-white'>Tax</strong> – Buy: 0%, Transfer: 0%, Sell: 2%
                </p>
                <p className='mb-2'>
                    <strong className='text-white'>Audit</strong> – <a href='https://difi.market/audit_difi.pdf'>Click Here</a>
                </p>
                <p className='mb-2'>
                    <strong className='text-white'>Whitepaper</strong> – <a href='https://difi.market/WhitePaper_difi.pdf'>Click Here</a>
                </p>
                <p className='mb-2'>
                    <strong className='text-white'>Trade PancakeSwap</strong> – <a href='#'>DIFI/WBNB (soon)</a>
                </p>
                <p className='mb-2'>
                    <strong className='text-white'>Official Twitter</strong> – <a href='https://twitter.com/difimarket'>Click Here</a>
                </p>
                <p className='mb-2'>
                    <strong className='text-white'>Official Telegram</strong> – <a href='https://t.me/difitoken'>Click Here</a>
                </p>
                <p className='mb-2'>
                    <strong className='text-white'>Official Instagram</strong> – <a href='https://instagram.com/difimarket'>Click Here</a>
                </p>
            </div>
        ),
    },
    {
        question: 'Functions of the DIFI Market?',
        answer: (
            <div className='text-muted'>
                <p className='mb-2'>On the DIFI market you can:</p>
                <ul className='mb-0 text-white'>
                    <li>Sell your files, programs and any type of digital file.</li>
                    <li>Purchasing other users Files.</li>
                    <li>Leave Feedback after a purchase.</li>
                    <li>Withdraw your DIFI Of sales.</li>
                </ul>
            </div>
        ),
    },
    {
        question: 'What wallets are supported?',
        answer: (
            <div className='text-muted'>
                <p className='mb-2'>At the moment, you can use MetaMask wallet. For mobile version open website directly on Metamask Dapp Browser, or use Browser with metamask extension.</p>
            </div>
        ),
    },
    {
        question: 'What are the commissions?',
        answer: (
            <div className='text-muted'>
                <p className='mb-2'>
                    <strong className='text-white'>Fees</strong> – 3% on each Sale.
                </p>
                <p className='mb-2'>
A 3% tax is applied to each file sold, the system uses the blockchain and IPFS, and the 3% tax on each sale allows us to keep the system up and running perfectly.
</p>
            </div>
        ),
    },
    {
        question: 'Upload Limits?',
        answer: (
            <div className='text-muted'>
                <p className='mb-2'>
                    The maximum upload limit for each file is about 100MB
                </p>
                <p className='mb-2 fw-bold'>There are no limits to the number of files a user can upload and sell.</p>
            </div>
        ),
    },
    {
        question: 'What do I buy the Files with?',
        answer: (
            <div className='text-muted'>
                <p className='mb-2'>The files can be purchased using the DIFI Token, even a few BNB are needed to manage the transactions as it is the native currency.</p>
            </div>
        ),
    },
    {
        question: "Can I change the price of a File?",
        answer: (
            <div className='text-muted'>
                <p className='mb-2'>
At the moment it is not possible to change the price of a file, do the calculations correctly based on the price of the DIFI when you sell a file.
</p>
            </div>
        ),
    },
    {
        question: 'More questions?',
        answer: (
            <div className='text-muted'>
                <p className='mb-2'>
                    If you can’t find the answers here,{' '}
                    <a
                        href='https://difi.market/features-requests/'
                        className='text-primary'
                        target='_blank'
                        rel='noopener noreferrer'
                    >
                        get in touch
                    </a>
                    . We will be happy to help.
                </p>
            </div>
        ),
    },
];
