import React from 'react';
import { FaStar } from 'react-icons/fa';

function RatingDisplay({ rating }) {
    return (
        <div>
            {[...Array(rating)].map((star, index) => {
                return <FaStar key={index} color='#ffc107' size={17} className='me-1' />;
            })}
        </div>
    );
}

export default RatingDisplay;

export function AvgRating({ reviews }) {
    const totalStars = reviews
        ?.map((rev) => rev?.stars)
        ?.reduce((total, star) => {
            return total + star;
        }, 0);

    const avgStars = totalStars / reviews?.length;

    return (
        <>
            {reviews?.length > 0 ? (
                <div className='mb-4 d-flex text-sm'>
                    <div className='mx-1'>
                        <RatingDisplay rating={Math.ceil(avgStars)} />
                    </div>
                    <span className='mx-1'>{avgStars.toFixed(1)}</span>
                </div>
            ) : (
                <p>No reviews yet</p>
            )}
        </>
    );
}

export function AvgRatingWithTotal({ reviews }) {
    const totalStars = reviews
        ?.map((rev) => rev?.stars)
        ?.reduce((total, star) => {
            return total + star;
        }, 0);

    const avgStars = totalStars / reviews?.length;

    return (
        <>
            {reviews?.length > 0 ? (
                <div className='mb-4 d-flex text-sm'>
                    <div className='mx-1'>
                        <RatingDisplay rating={Math.ceil(avgStars)} />
                    </div>
                    <span className='mx-1'>{avgStars.toFixed(1)}</span>
                    <p className='mb-0 mx-1 text-muted'>
                        From <strong className='fw-bold text-white'>{reviews?.length}</strong> Ratings
                    </p>
                </div>
            ) : (
                <p>No reviews yet</p>
            )}
        </>
    );
}
