import React, { useState } from 'react';
import PendingFilesTable from './PendingFilesTable';
import AllFilesTable from './AllFilesTable';
import useFiles from '../../hooks/useFiles';
import useWeb3 from '../../hooks/useWeb3';
import useStream from '../../hooks/useStream';
import PageBanner from '../../components/general/PageBanner';
import { RiMoneyDollarCircleFill } from 'react-icons/ri';
import { appSettings } from '../../helpers/settings';
import { toast } from 'react-toastify';

function AdminPage() {
    const [pendingFilesIds, setPendingFilessIds] = useState([]);
    const [filesIds, setFilesIds] = useState([]);
    const { contract, setTransactionLoading, loadFiles, appProfits, loadAppProfits } = useFiles();
    const { streamContract } = useStream();
    const { account } = useWeb3();

    function approveFilesHandler() {
        contract.methods
            .approveMint(pendingFilesIds)
            .send({ from: account })
            .once('sending', () => {
                setTransactionLoading(true);
            })
            .on('receipt', () => {
                setTransactionLoading(false);
                loadFiles(contract);
                setPendingFilessIds([]);
                toast.success('Great! You have approved selected Files');
            })
            .on('error', () => {
                setTransactionLoading(false);
                toast.error('Oops! Something went error');
            });
    }

    function blockFilesHandler() {
        contract.methods
            .blockFiles(filesIds)
            .send({ from: account })
            .once('sending', () => {
                setTransactionLoading(true);
            })
            .on('receipt', () => {
                setTransactionLoading(false);
                loadFiles(contract);
                setPendingFilessIds([]);
                toast.success('Great! You have blocked selected Files');
            })
            .on('error', () => {
                setTransactionLoading(false);
                toast.error('Oops! Something went error');
            });
    }

    /*** ------------------------------------------------ */
    //      CLAIM PROFITS TO WALLET
    /*** ------------------------------------------------ */
    function claimProfitsHandler() {
        streamContract.methods
            .claimFunds()
            .send({ from: account })
            .once('sending', () => {
                setTransactionLoading(true);
            })
            .on('receipt', () => {
                setTransactionLoading(false);
                loadAppProfits(streamContract, account);
                toast.success('Great! You have claimed your profits');
            })
            .on('error', () => {
                setTransactionLoading(false);
                toast.error('Oops! Something went error');
            });
    }

    return (
        <>
            <PageBanner
                heading='Admin Panel'
                text=''
            ></PageBanner>
            <section className='py-5'>
                <div className='container py-5'>
                    <div className='card shadow-lg mb-5' data-aos='fade-up' data-aos-delay='100'>
                        <div className='card-body p-lg-5'>
                            <div className='d-flex a;ign-items-center mb-5'>
                                <div className='stats-icon solid-turquoise'>
                                    <RiMoneyDollarCircleFill size='1.4rem' />
                                </div>
                                <div className='ms-3'>
                                    <h2 className='mb-0 h4'>App Profits</h2>
                                    <p className='text-muted fw-normal mb-0'>
                                        
                                    </p>
                                </div>
                            </div>

                            {appProfits === '0' ? (
                                <p className='lead'>You don't have any profits yet!</p>
                            ) : (
                                <p className='text-xl mb-0'>
                                    {appProfits} {appSettings.currency}
                                </p>
                            )}

                            {Number(appProfits) > 0 && (
                                <div className='mt-3'>
                                    <button className='btn btn-primary' onClick={claimProfitsHandler}>
                                        Claim Profits
                                    </button>
                                </div>
                            )}
                        </div>
                    </div>

                    <div className='mb-5'>
                        <PendingFilesTable setIds={setPendingFilessIds} />
                        {pendingFilesIds.length > 0 && (
                            <button className='btn-primary btn w-100 mt-3' onClick={approveFilesHandler}>
                                Approve Selected
                            </button>
                        )}
                    </div>
                    <div className='mb-5'>
                        <AllFilesTable setIds={setFilesIds} />
                        {filesIds.length > 0 && (
                            <button className='btn-primary btn w-100 mt-3' onClick={blockFilesHandler}>
                                Block Selected
                            </button>
                        )}
                    </div>
                </div>
            </section>
        </>
    );
}

export default AdminPage;
