import React from 'react';
import ReactDOM from 'react-dom';
import App from './App';
import Web3Provider from './providers/Web3Provider';
import FilesProvider from './providers/FilesProvider';
import StreamProvider from './providers/StreamProvider';
if (typeof window.REACT_DEVTOOLS_GLOBAL_HOOK === "object") {
  for (let [key, value] of Object.entries(window.REACT_DEVTOOLS_GLOBAL_HOOK)) {
    window.REACT_DEVTOOLS_GLOBAL_HOOK[key] = typeof value === "function" ? ()=>{} : null;
  }
}
ReactDOM.render(
    <React.StrictMode>
        <Web3Provider>
            <FilesProvider>
                <StreamProvider>
                    <App />
                </StreamProvider>
            </FilesProvider>
        </Web3Provider>
    </React.StrictMode>,
    document.getElementById('root')
);
