import React, { useEffect, useState } from 'react';
import { Link, useNavigate } from 'react-router-dom';
import { truncateStart } from '../../helpers/utils';
import { BsFillCalendar2MinusFill } from 'react-icons/bs';
import { FaCloudDownloadAlt } from 'react-icons/fa';
import { MdComputer } from 'react-icons/md';
import { BsImages } from 'react-icons/bs';
import { Jazzicon } from '@ukstv/jazzicon-react';
import { appSettings } from '../../helpers/settings';
import Web3 from 'web3';
import { toast } from 'react-toastify';
import { formatSimpleDate } from '../../helpers/utils';
import useFiles from '../../hooks/useFiles';
import useWeb3 from '../../hooks/useWeb3';
import useStream from '../../hooks/useStream';
import WatchListAction from '../../components/general/WatchListAction';
import ConnectWalletHandler from '../../components/general/ConnectWalletHandler';
import Viewer from 'react-viewer';
import { AvgRatingWithTotal } from '../../components/general/RatingDisplay';

function HeroSlide({ title, metadata, createdAt, id, price, creator, reviews }) {
    const { contract, loadFileBuyers, files, loadFiles, setTransactionLoading } = useFiles();
    const { streamContract, paymentTokenContract } = useStream();
    const [fileBuyers, setFileBuyers] = useState(null);
    const [isSubscriber, setIsSubscriber] = useState(false);
    const [isOpen, setIsOpen] = useState(false);
    const { account } = useWeb3();
    const navigate = useNavigate();
    const images = metadata?.screenshots?.map((img) => ({ src: img }));

    function buyFile() {
        paymentTokenContract.methods
            .approve(streamContract.options.address, Web3.utils.toWei(price.toString(), 'ether'))
            .send({ from: account })
            .once('sending', () => {
                setTransactionLoading(true);
            })
            .on('transactionHash', (hash) => {
                setTransactionLoading(true);
            })
            .on('receipt', (receipt) => {
                paymentTokenContract.methods
                    .transfer(account, Web3.utils.toWei(price.toString(), 'ether'))
                    .send({ from: account })
                    .once('sending', () => {
                        setTransactionLoading(true);
                    })
                    .on('receipt', (receipt) => {
                        streamContract.methods
                            .buyFile(Number(id), Web3.utils.toWei(price.toString(), 'ether'))
                            .send({ from: account })
                            .once('sending', () => {
                                setTransactionLoading(true);
                            })
                            .on('receipt', () => {
                                setTransactionLoading(false);
                                loadFiles(contract);
                                navigate(`/files/${id}`);
                                toast.success('Great! You have access now');
                            })
                            .on('error', () => {
                                setTransactionLoading(false);
                                toast.error('Oops! Something went error');
                            });
                    })
                    .on('error', () => {
                        setTransactionLoading(false);
                        toast.error('Oops! Something went error');
                    });
            })
            .on('error', () => {
                setTransactionLoading(false);
                toast.error('Oops! Something went error');
            });
    }

    useEffect(() => {
        if (contract && files.length > 0) {
            async function getFileBuyers() {
                const subs = await loadFileBuyers(contract, Number(id));
                const formattedSubs = subs.map((subscriber) => subscriber[0]);
                setFileBuyers([...new Set(formattedSubs)]);
            }
            getFileBuyers();
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [contract, files]);

    useEffect(() => {
        if (account && contract) {
            const checkSubscriber = fileBuyers?.includes(account);
            setIsSubscriber(checkSubscriber);
        }
    }, [account, contract, fileBuyers]);

    return (
        <div className='hero-slide py-5 overflow-hidden'>
            <div className='hero-slide-bg' style={{ backgroundImage: `url(${metadata.thumbnail})` }}></div>
            <div className='container z-index-20 py-5 mt-5'>
                <div className='row gy-5 align-items-center'>
                    <div className='col-lg-6'>
                        <ul className='list-inline' data-aos='fade-right'>
                            <li className='list-inline-item'>
                                <div className='badge bg-primary fw-normal rounded-0'>{metadata.category}</div>
                            </li>
                        </ul>
                        <h2 className='h1 text-xxl text-shadow' data-aos='fade-right' data-aos-delay='100'>
                            {metadata.title}
                        </h2>
                        <ul
                            className='list-inline d-flex align-items-center'
                            data-aos='fade-right'
                            data-aos-delay='200'
                        >
                            <li className='list-inline-item'>
                                <span className='small ms-2'>
                                    <BsFillCalendar2MinusFill className='text-warning me-2' size='1.2rem' />{' '}
                                    {formatSimpleDate(createdAt)}
                                </span>
                            </li>
                            <li className='list-inline-item ms-2 lh-1' style={{ fontSize: '0.7rem' }}>
                                |
                            </li>
                            <li className='list-inline-item'>
                                <span className='small ms-2'>
                                    <MdComputer className='text-warning me-2' size='1.4rem' /> {metadata.compatibility}
                                </span>
                            </li>
                        </ul>
                        <p className='text-gray-500 lead fw-light mb-4' data-aos='fade-right' data-aos-delay='300'>
                            {truncateStart(metadata.description, 200, '....')}
                        </p>
                        <div className='mb-4' data-aos='fade-right' data-aos-delay='350'>
                            <AvgRatingWithTotal reviews={reviews} />
                        </div>
                        {account ? (
                            <ul className='list-inline' data-aos='fade-right' data-aos-delay='400'>
                                {isSubscriber ? (
                                    <li className='list-inline-item'>
                                        <Link className='btn btn-primary' to={`/files/${id}`}>
                                            <FaCloudDownloadAlt className='mb-1 me-2' size='1.35rem' />
                                            Download Now
                                        </Link>
                                    </li>
                                ) : (
                                    <li className='list-inline-item'>
                                        <button type='button' className='btn btn-primary' onClick={buyFile}>
                                            <FaCloudDownloadAlt className='mb-1 me-2' size='1.35rem' />
                                            Access Now
                                        </button>
                                    </li>
                                )}
                                <WatchListAction id={id} creator={creator} />
                            </ul>
                        ) : (
                            <ConnectWalletHandler customClass='my-3' />
                        )}

                        {isSubscriber ? (
                            <p className='small' data-aos='fade-up' data-aos-delay='500'>
                                You already have access to this file
                            </p>
                        ) : (
                            <p className='small' data-aos='fade-up' data-aos-delay='500'>
                                Access this file for only{' '}
                                <span className='text-primary'>
                                    {price} {appSettings.currency}
                                </span>
                            </p>
                        )}
                        {fileBuyers && (
                            <>
                                <ul className='list-unstyled avatars mt-4' data-aos='fade-right' data-aos-delay='400'>
                                    {fileBuyers.slice(0, 3).map((buyer, i) => {
                                        return (
                                            <li className='avatar avatar-md2' key={i}>
                                                <div style={{ width: '40px', height: '40px' }}>
                                                    <Jazzicon address={buyer || ''} />
                                                </div>
                                            </li>
                                        );
                                    })}
                                </ul>
                            </>
                        )}
                    </div>
                    <div className='col-lg-5 ms-auto'>
                        <div className='px-lg-5' data-aos='fade-left'>
                            <div className='position-relative'>
                                <img
                                    src={metadata.thumbnail}
                                    alt={title}
                                    className='img-fluid hero-slide-img d-block ms-auto shadow-lg w-100'
                                />
                                <button
                                    className='screenshots-btn shadow-0'
                                    type='button'
                                    onClick={() => {
                                        setIsOpen(true);
                                    }}
                                >
                                    <BsImages size='1.4rem' />
                                </button>
                            </div>
                        </div>
                    </div>
                </div>
            </div>

            {isOpen && (
                <Viewer
                    visible={isOpen}
                    onClose={() => {
                        setIsOpen(false);
                    }}
                    images={images}
                />
            )}
        </div>
    );
}

export default HeroSlide;
