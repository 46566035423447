import React, { useMemo } from 'react';
import { useParams } from 'react-router-dom';
import useFiles from '../../hooks/useFiles';
import Hero from './Hero';

function FileSinglePage() {
    const { id } = useParams();
    const { files } = useFiles();

    const vidDetails = useMemo(() => {
        if (files.length > 0) {
            return files.filter((vid) => vid.id === id)[0];
        } else {
            return null;
        }
    }, [files, id]);

    return <>{vidDetails ? <Hero {...vidDetails} /> : <p>Loading File</p>}</>;
}

export default FileSinglePage;
