import React, { useState, useEffect } from 'react';
import { Swiper, SwiperSlide } from 'swiper/react';
import { HiPresentationChartLine } from 'react-icons/hi';
import useFiles from '../../hooks/useFiles';
import FileBox from '../../components/general/FileBox';
import FileBoxMock from '../../mock-components/FileBoxMock';

function TopTrending() {
    const { contract, loadFileBuyers, files } = useFiles();
    const fallbackFiles = [1, 2, 3, 4, 5, 6, 7, 8];
    const [fileBuyers, setFileBuyers] = useState([]);

    /* ------------------------------------------------ */
    //      GET VIDEO WITH MOST buyers
    /* ------------------------------------------------ */
    useEffect(() => {
        let cancelled = false;
        if (contract && files.length > 0) {
            async function getFileBuyers() {
                let filesWithSubs = [];
                for (let i = 0; i < files.length; i++) {
                    const subs = await loadFileBuyers(contract, Number(i + 1));
                    filesWithSubs.push({ ...files[i], buyers: [...subs?.map((sub) => sub[0])] });
                }
                setFileBuyers(filesWithSubs);
            }
            if (!cancelled) {
                getFileBuyers();
            }
        }

        // CLEANUP ----------------------------
        return () => (cancelled = true);
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [contract, files]);

    return (
        <section className='pb-5 '>
            <div className='container pb-5'>
                <h2 className='text-lg lh-1 mb-4 d-flex align-items-center'>
                    <HiPresentationChartLine size='3.5rem' className='text-primary' />
                    <div className='ms-2'>Top Trending</div>
                </h2>
                <div className='swiper-slow swiper-wrapper-padding'>
                    <Swiper
                        spaceBetween={30}
                        breakpoints={{
                            640: {
                                slidesPerView: 1,
                            },
                            768: {
                                slidesPerView: 2,
                            },
                            991: {
                                slidesPerView: 3,
                            },
                            1200: {
                                slidesPerView: 4,
                            },
                        }}
                    >
                        {files?.filter((vid) => vid.approved)?.length > 0
                            ? fileBuyers
                                  .filter((vid) => vid.approved)
                                  .sort((a, b) => b?.buyers?.length - a?.buyers?.length)
                                  .slice(0, 8)
                                  .map((vid) => {
                                      return (
                                          <SwiperSlide key={vid.id}>
                                              <FileBox {...vid} />
                                          </SwiperSlide>
                                      );
                                  })
                            : fallbackFiles.map((vid, i) => {
                                  return (
                                      <SwiperSlide key={i}>
                                          <FileBoxMock />
                                      </SwiperSlide>
                                  );
                              })}
                    </Swiper>
                </div>
            </div>
        </section>
    );
}

export default TopTrending;
