import React, { useReducer } from 'react';
import { uniqueNamesGenerator, starWars } from 'unique-names-generator';
import Web3 from 'web3';

import FilesContext from './files-context';

const defaultFilesState = {
    files: [],
    activities: [],
    contract: null,
    owner: '',
    ratingModalState: false,
    mintUploadProgress: 0,
    previewModal: false,
    preview: {},
    ratingModalSrc: {},
    buyers: [],
    transactionLoading: false,
    uploadingProgress: false,
    userVideos: [],
    appProfits: '0',
};

const filesReducer = (state, action) => {
    if (action.type === 'GET_FILES') {
        return {
            ...state,
            files: action.files.map((file) => {
                return {
                    id: file[0],
                    metadata: {
                        title: file[1][0],
                        description: file[1][1],
                        category: file[1][2],
                        type: file[1][3],
                        file: file[1][4],
                        screenshots: file[1][5],
                        compatibility: `${file[1][6]?.map((os, i) =>
                            i < file[1][6]?.length - 1 ? `${os}` : os
                        )}`?.replaceAll(',', ', '),
                        thumbnail: file[1][7],
                    },
                    price: Number(Web3.utils.fromWei(file[2], 'ether')),
                    creator: file[3],
                    userGenName: uniqueNamesGenerator({
                        dictionaries: [starWars],
                        seed: file[3],
                    }).replace('_', ' '),
                    approved: file[5],
                    createdAt: Number(file[6]) * 1000,
                    promoted: file[7],
                    reviews: file[8]?.map((rating) => ({
                        account: rating[0],
                        accountGenName: uniqueNamesGenerator({
                            dictionaries: [starWars],
                            seed: rating[0],
                        }).replace('_', ' '),
                        stars: Number(rating[2]),
                        message: rating[1],
                        reason: rating[3],
                    })),
                };
            }),
        };
    }
    if (action.type === 'GET_BUYERS') {
        return {
            ...state,
            buyers: action.buyers.map((buyer) => {
                return buyer[0];
            }),
        };
    }
    if (action.type === 'CONTRACT') {
        return {
            ...state,
            contract: action.contract,
        };
    }
    if (action.type === 'LOADING') {
        return {
            ...state,
            transactionLoading: action.loading,
        };
    }
    if (action.type === 'UPLOADING_PROGRESS') {
        return {
            ...state,
            uploadingProgress: action.loading,
        };
    }
    if (action.type === 'SET_PREVIEW_MODAL') {
        return {
            ...state,
            previewModal: action.status,
        };
    }
    if (action.type === 'SET_RATING_MODAL_STATE') {
        return {
            ...state,
            ratingModalState: action.state,
        };
    }
    if (action.type === 'SET_RATING_MODAL_SRC') {
        return {
            ...state,
            ratingModalSrc: {
                id: action.src.id,
                title: action.src.title,
            },
        };
    }
    if (action.type === 'GET_PROFITS') {
        return {
            ...state,
            appProfits: Web3.utils.fromWei(action.profits, 'ether'),
        };
    }
    if (action.type === 'GET_UPLOAD_PROGRESS') {
        return {
            ...state,
            mintUploadProgress: action.progress,
        };
    }
    if (action.type === 'LOAD_ACTIVITIES') {
        return {
            ...state,
            activities: action.activities.map((el) => {
                return {
                    user: el[0],
                    time: Number(el[2]) * 1000,
                    action: el[3],
                };
            }),
        };
    }
    if (action.type === 'SET_PRIVEW_SRC') {
        return {
            ...state,
            preview: {
                src: action.preview.src,
                id: action.preview.id,
                buyers: action.preview.buyers,
                price: action.preview.price,
                creator: action.preview.creator,
            },
        };
    }
    if (action.type === 'GET_OWNER') {
        return {
            ...state,
            owner: action.owner,
        };
    }

    return defaultFilesState;
};

const FilesProvider = (props) => {
    const [filesState, dispatchFilesAction] = useReducer(filesReducer, defaultFilesState);

    const setTransactionLoadingHandler = (loading) => {
        dispatchFilesAction({ type: 'LOADING', loading: loading });
    };

    const setUploadingProgressHandler = (loading) => {
        dispatchFilesAction({ type: 'UPLOADING_PROGRESS', loading: loading });
    };

    const setPreviewModalHandler = (status) => {
        dispatchFilesAction({ type: 'SET_PREVIEW_MODAL', status: status });
    };

    const setPreviewSrcHandler = (src, buyers, id, price, creator) => {
        dispatchFilesAction({ type: 'SET_PRIVEW_SRC', preview: { src, id, buyers, price, creator } });
    };

    const setRatingModalStateHandler = (state) => {
        dispatchFilesAction({ type: 'SET_RATING_MODAL_STATE', state: state });
    };
    const setRatingModalSrcHandler = (id, title, rating, ratingReason, ratingMsg) => {
        dispatchFilesAction({
            type: 'SET_RATING_MODAL_SRC',
            src: { id: id, title: title },
        });
    };

    const loadContractHandler = (web3, VideoAbi, deployedNetwork) => {
        const contract = deployedNetwork ? new web3.eth.Contract(VideoAbi.abi, deployedNetwork.address) : '';
        dispatchFilesAction({ type: 'CONTRACT', contract: contract });
        return contract;
    };

    const loadFilesHandler = async (contract) => {
        const files = await contract.methods.getFiles().call();
        dispatchFilesAction({ type: 'GET_FILES', files: files });
        return files;
    };

    const loadFileBuyersHandler = async (contract, id) => {
        const buyers = await contract.methods.getSubscribers(id).call();
        dispatchFilesAction({ type: 'GET_BUYERS', buyers: buyers });
        return buyers;
    };

    const loadAppOwnerHandler = async (contract) => {
        const owner = await contract.methods.owner().call();
        dispatchFilesAction({ type: 'GET_OWNER', owner: owner });
        return owner;
    };

    const loadAppProfitsHandler = async (contract, address) => {
        const profits = await contract.methods.user_funds(address).call();
        dispatchFilesAction({ type: 'GET_PROFITS', profits: profits });
        return profits;
    };

    const loadMintUploadProgressHandler = (progress) => {
        dispatchFilesAction({ type: 'GET_MINT_PROGRESS', progress: progress });
        return progress;
    };

    const loadActivitiesHandler = async (contract) => {
        const activities = await contract.methods.get_activities().call();
        dispatchFilesAction({ type: 'LOAD_ACTIVITIES', activities: activities });
        return activities;
    };

    const filesContext = {
        files: filesState.files,
        contract: filesState.contract,
        buyers: filesState.buyers,
        owner: filesState.owner,
        appProfits: filesState.appProfits,
        previewModal: filesState.previewModal,
        mintUploadProgress: filesState.mintUploadProgress,
        preview: filesState.preview,
        ratingModalSrc: filesState.ratingModalSrc,
        ratingModalState: filesState.ratingModalState,
        transactionLoading: filesState.transactionLoading,
        uploadingProgress: filesState.uploadingProgress,
        activities: filesState.activities,
        loadContract: loadContractHandler,
        loadFiles: loadFilesHandler,
        loadFileBuyers: loadFileBuyersHandler,
        loadAppOwner: loadAppOwnerHandler,
        setTransactionLoading: setTransactionLoadingHandler,
        setPreviewModal: setPreviewModalHandler,
        loadMintUploadProgress: loadMintUploadProgressHandler,
        setPreviewSrc: setPreviewSrcHandler,
        loadAppProfits: loadAppProfitsHandler,
        setUploadingProgress: setUploadingProgressHandler,
        loadActivities: loadActivitiesHandler,
        setRatingModalState: setRatingModalStateHandler,
        setRatingModalSrc: setRatingModalSrcHandler,
    };

    return <FilesContext.Provider value={filesContext}>{props.children}</FilesContext.Provider>;
};

export default FilesProvider;
