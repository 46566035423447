import React, { useState, useEffect } from 'react';
import { BsFillCalendar2MinusFill } from 'react-icons/bs';
import { MdComputer, MdReviews } from 'react-icons/md';
import { AiOutlineExpand } from 'react-icons/ai';
import { FaCloudDownloadAlt } from 'react-icons/fa';
import { formatSimpleDate, truncate } from '../../helpers/utils';
import useFiles from '../../hooks/useFiles';
import useWeb3 from '../../hooks/useWeb3';
import useStream from '../../hooks/useStream';
import { Jazzicon } from '@ukstv/jazzicon-react';
import { appSettings } from '../../helpers/settings';
import { toast } from 'react-toastify';
import WatchListAction from '../../components/general/WatchListAction';
import { Swiper, SwiperSlide } from 'swiper/react';
import Viewer from 'react-viewer';
import Web3 from 'web3';
import ConnectWalletHandler from '../../components/general/ConnectWalletHandler';
import { Pagination } from 'swiper';
import RatingDisplay from '../../components/general/RatingDisplay';

function Hero({ createdAt, metadata, creator, price, id, approved, reviews }) {
    const {
        contract,
        setRatingModalState,
        setRatingModalSrc,
        loadFileBuyers,
        files,
        loadFiles,
        setTransactionLoading,
    } = useFiles();
    const { streamContract, paymentTokenContract } = useStream();
    const { account, username } = useWeb3();
    const [fileBuyers, setFileBuyers] = useState(null);
    const [isSubscriber, setIsSubscriber] = useState(false);
    const images = metadata?.screenshots?.map((img) => ({ src: img }));
    const [isOpen, setIsOpen] = useState(false);
    const [activeIndex, setActiveIndex] = useState(0);

    function BuyFileHandler() {
        paymentTokenContract.methods
            .approve(streamContract.options.address, Web3.utils.toWei(price.toString(), 'ether'))
            .send({ from: account })
            .once('sending', () => {
                setTransactionLoading(true);
            })
            .on('transactionHash', (hash) => {
                setTransactionLoading(true);
            })
            .on('receipt', (receipt) => {
                paymentTokenContract.methods
                    .transfer(account, Web3.utils.toWei(price.toString(), 'ether'))
                    .send({ from: account })
                    .once('sending', () => {
                        setTransactionLoading(true);
                    })
                    .on('receipt', (receipt) => {
                        streamContract.methods
                            .buyFile(Number(id), Web3.utils.toWei(price.toString(), 'ether'))
                            .send({ from: account })
                            .once('sending', () => {
                                setTransactionLoading(true);
                            })
                            .on('receipt', () => {
                                setTransactionLoading(false);
                                loadFiles(contract);
                                toast.success('Great! You have access now');
                            })
                            .on('error', () => {
                                setTransactionLoading(false);
                                toast.error('Oops! Something went error');
                            });
                    })
                    .on('error', () => {
                        setTransactionLoading(false);
                        toast.error('Oops! Something went error');
                    });
            })
            .on('error', () => {
                setTransactionLoading(false);
                toast.error('Oops! Something went error');
            });
    }

    useEffect(() => {
        if (contract && files.length > 0) {
            async function getFileBuyers() {
                const subs = await loadFileBuyers(contract, Number(id));
                const formattedSubs = subs.map((subscriber) => subscriber[0]);
                setFileBuyers([...new Set(formattedSubs)]);
            }
            getFileBuyers();
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [contract, files]);

    useEffect(() => {
        if (account && contract) {
            const checkSubscriber = fileBuyers?.includes(account);
            setIsSubscriber(checkSubscriber);
        }
    }, [account, contract, fileBuyers]);

    const totalStars = reviews
        ?.map((rev) => rev?.stars)
        ?.reduce((total, star) => {
            return total + star;
        }, 0);

    const avgStars = totalStars / reviews?.length;

    return (
        <>
            <div className='video-single-hero hero-slide py-5 overflow-hidden'>
                <div className='hero-slide-bg' style={{ backgroundImage: `url(${metadata.thumbnail})` }}></div>
                <div className='container z-index-20 py-5 mt-5 text-center'>
                    <div className='row gy-5 align-items-center'>
                        <div className='col-lg-9 mx-auto'>
                            <ul className='list-inline' data-aos='fade-up'>
                                <li className='list-inline-item'>
                                    <div className='badge bg-green fw-normal rounded-0'>{metadata.category}</div>
                                </li>
                            </ul>

                            <h2 className='h1 text-xxl text-shadow' data-aos='fade-up' data-aos-delay='100'>
                                {metadata.title}
                            </h2>
                            <div className='d-block' data-aos='fade-up' data-aos-delay='300'>
                                {approved || (
                                    <div className='bg-danger px-2 text-white d-inline-block mb-4 fw-light'>
                                        <strong className='me-2 headings-font-family'>Pending!</strong>
                                        This file is waiting for admin approval
                                    </div>
                                )}
                            </div>
                            <ul
                                className='list-inline d-flex align-items-center justify-content-center'
                                data-aos='fade-up'
                                data-aos-delay='200'
                            >
                                <li className='list-inline-item'>
                                    <span className='small ms-2'>
                                        <BsFillCalendar2MinusFill className='text-warning me-2' size='1.2rem' />{' '}
                                        {formatSimpleDate(createdAt)}
                                    </span>
                                </li>
                                <li className='list-inline-item ms-2 lh-1' style={{ fontSize: '0.7rem' }}>
                                    |
                                </li>
                                <li className='list-inline-item'>
                                    <span className='small ms-2'>
                                        <MdComputer className='text-warning me-2' size='1.4rem' />{' '}
                                        {metadata.compatibility}
                                    </span>
                                </li>
                            </ul>
                            <p className='text-gray-500 lead fw-light mb-4' data-aos='fade-up' data-aos-delay='300'>
                                {metadata.description}
                            </p>
                            <div data-aos='fade-up' data-aos-delay='350'>
                                {reviews?.length > 0 ? (
                                    <div className='mb-4 d-flex justify-content-center align-items-center'>
                                        <div className='mx-1'>
                                            <RatingDisplay rating={Math.ceil(avgStars)} />
                                        </div>
                                        <span className='mx-1'>{avgStars.toFixed(1)}</span>
                                        <p className='mb-0 mx-1 text-muted'>
                                            From <strong className='fw-bold text-white'>{reviews?.length}</strong>{' '}
                                            Ratings
                                        </p>
                                    </div>
                                ) : (
                                    <p>No reviews yet</p>
                                )}
                            </div>
                            {account ? (
                                <ul className='list-inline mb-0' data-aos='fade-up' data-aos-delay='400'>
                                    {isSubscriber || creator === account ? (
                                        <li className='list-inline-item m-2'>
                                            <a href={metadata.file} className='btn btn-primary'>
                                                <FaCloudDownloadAlt className='mb-1 me-2' size='1.35rem' />
                                                Download Now
                                            </a>
                                        </li>
                                    ) : (
                                        <li className='list-inline-item m-2'>
                                            <button type='button' className='btn btn-primary' onClick={BuyFileHandler}>
                                                <FaCloudDownloadAlt className='mb-1 me-2' size='1.35rem' />
                                                Access Now
                                            </button>
                                        </li>
                                    )}
                                    <WatchListAction id={id} creator={creator} />
                                </ul>
                            ) : (
                                <ConnectWalletHandler customClass='my-3' />
                            )}

                            {isSubscriber ? (
                                creator !== account &&
                                (reviews?.map((rev) => rev.account).includes(account) ? (
                                    <div className='mt-3' data-aos='fade-up' data-aos-delay='550'>
                                        You've already reviewed this file
                                        <RatingDisplay
                                            rating={reviews?.filter((rev) => rev.account === account)[0]?.stars}
                                        />
                                    </div>
                                ) : (
                                    <button
                                        className='btn btn-warning'
                                        onClick={() => {
                                            setRatingModalState(true);
                                            setRatingModalSrc(id, metadata.title);
                                        }}
                                        data-aos='fade-up'
                                        data-aos-delay='550'
                                    >
                                        <MdReviews className='mb-1 me-2' size='1.35rem' />
                                        Rate this File
                                    </button>
                                ))
                            ) : (
                                <p className='small' data-aos='fade-up' data-aos-delay='500'>
                                    Access this file for only{' '}
                                    <span className='text-primary'>
                                        {price} {appSettings.currency}
                                    </span>
                                </p>
                            )}
                            {fileBuyers && (
                                <>
                                    <ul className='list-unstyled avatars mt-4' data-aos='fade-up' data-aos-delay='400'>
                                        {fileBuyers.slice(0, 3).map((subscriber, i) => {
                                            return (
                                                <li className='avatar avatar-md2' key={i}>
                                                    <div style={{ width: '40px', height: '40px' }}>
                                                        <Jazzicon address={subscriber || ''} />
                                                    </div>
                                                </li>
                                            );
                                        })}
                                    </ul>
                                </>
                            )}
                        </div>
                    </div>
                </div>
            </div>

            <section className='pb-5'>
                <div className='container swiper-pagination-wrapper pb-5' data-aos='fade-up' data-aos-delay='100'>
                    <h2 className='text-center h1 text-xl mb-5'>File Gallery</h2>
                    <Swiper
                        spaceBetween={15}
                        breakpoints={{
                            640: {
                                slidesPerView: 1,
                            },
                            768: {
                                slidesPerView: 2,
                            },
                            991: {
                                slidesPerView: 3,
                            },
                            1200: {
                                slidesPerView: 4,
                            },
                        }}
                        pagination={{
                            clickable: true,
                        }}
                        modules={[Pagination]}
                    >
                        {images.map((img, index) => {
                            return (
                                <SwiperSlide key={index}>
                                    <div
                                        onClick={() => {
                                            setIsOpen(true);
                                            setActiveIndex(index);
                                        }}
                                        className='screenshot-holder'
                                        style={{ backgroundImage: `url(${img.src})` }}
                                    >
                                        <AiOutlineExpand size='2.5rem' className='screenshot-icon' />
                                    </div>
                                </SwiperSlide>
                            );
                        })}
                    </Swiper>
                </div>
            </section>

            <section className='pb-5'>
                <div className='container pb-5' data-aos='fade-up' data-aos-delay='100'>
                    <div className='row'>
                        <div className='col-lg-6'>
                            <h2 className='h1 text-xl mb-5'>File Reviews</h2>
                            {reviews?.length > 0 ? (
                                <>
                                    {reviews?.map((rev, index) => {
                                        return (
                                            <div key={index}>
                                                <div className='mb-4'>
                                                    <div className='d-flex align-items-center mb-2'>
                                                        <div className='avatar avatar-md2'>
                                                            <div style={{ width: '40px', height: '40px' }}>
                                                                <Jazzicon address={rev?.account || ''} />
                                                            </div>
                                                        </div>
                                                        <div className='ms-3'>
                                                            <h6 className='mb-1' style={{ fontSize: '0.9rem' }}>
                                                                {rev?.account !== account
                                                                    ? rev?.accountGenName
                                                                    : username}
                                                            </h6>
                                                            <p className='text-muted small mb-0'>
                                                                {truncate(rev?.account, 15, '.....')}
                                                            </p>
                                                        </div>
                                                    </div>
                                                    <div className='mb-3'>
                                                        <RatingDisplay rating={rev?.stars} />
                                                    </div>
                                                    <h5 className='mb-2'>{rev?.reason}</h5>
                                                    <p className='text-muted mb-0'>{rev?.message}</p>
                                                </div>
                                                {index !== reviews?.length - 1 && <hr className='mb-4' />}
                                            </div>
                                        );
                                    })}
                                </>
                            ) : (
                                <p>This file has no reviews yet</p>
                            )}
                        </div>
                    </div>
                </div>
            </section>

            {isOpen && (
                <Viewer
                    visible={isOpen}
                    activeIndex={activeIndex}
                    onClose={() => {
                        setIsOpen(false);
                    }}
                    images={images}
                />
            )}
        </>
    );
}

export default Hero;
