import React from 'react';
import { Link } from 'react-router-dom';
import UserApprovedFilesTable from './UserApprovedFilesTable';
import { RiMoneyDollarCircleFill } from 'react-icons/ri';
import UserPendingFilesTable from './UserPendingFilesTable';
import UserPurchasedFiles from './UserPurchasedFiles';
import useFiles from '../../hooks/useFiles';
import PageBanner from '../../components/general/PageBanner';
import useWeb3 from '../../hooks/useWeb3';
import useStream from '../../hooks/useStream';
import ConnectWalletHander from '../../components/general/ConnectWalletHandler';
import { appSettings } from '../../helpers/settings.js';
import { toast } from 'react-toastify';

function UserPage() {
    const { account } = useWeb3();
    const { streamContract } = useStream();
    const { setTransactionLoading, appProfits, loadAppProfits } = useFiles();

    /*** ------------------------------------------------ */
    //      CLAIM PROFITS TO WALLET
    /*** ------------------------------------------------ */
    function claimProfitsHandler() {
        streamContract.methods
            .claimFunds()
            .send({ from: account })
            .once('sending', () => {
                setTransactionLoading(true);
            })
            .on('receipt', () => {
                setTransactionLoading(false);
                loadAppProfits(streamContract, account);
                toast.success('Great! You have claimed your profits');
            })
            .on('error', () => {
                setTransactionLoading(false);
                toast.error('Oops! Something went error');
            });
    }

    return (
        <>
            <PageBanner
                heading='My Account'
                text='Lorem ipsum dolor sit amet consectetur adipisicing elit. Quae, similique pariatur et corporis cum vero minus exercitationem veritatis.'
            >
                {account ? (
                    <Link className='btn btn-primary' to='/mint'>
                        Sell New Product
                    </Link>
                ) : (
                    <ConnectWalletHander customClass='my-3' />
                )}
            </PageBanner>
            <section className='py-5'>
                <div className='container py-5'>
                    <div className='card shadow-lg mb-5' data-aos='fade-up' data-aos-delay='100'>
                        <div className='card-body p-lg-5'>
                            <div className='d-flex a;ign-items-center mb-5'>
                                <div className='stats-icon solid-turquoise'>
                                    <RiMoneyDollarCircleFill size='1.4rem' />
                                </div>
                                <div className='ms-3'>
                                    <h2 className='mb-0 h4'>Sales Profits</h2>
                                    <p className='text-muted fw-normal mb-0'>
                                    </p>
                                </div>
                            </div>

                            {appProfits === '0' ? (
                                <p className='lead'>You don't have any profits yet!</p>
                            ) : (
                                <p className='text-xl mb-0'>
                                    {appProfits} {appSettings.currency}
                                </p>
                            )}

                            {Number(appProfits) > 0 && (
                                <div className='mt-3'>
                                    <button className='btn btn-primary' onClick={claimProfitsHandler}>
                                        Claim Profits
                                    </button>
                                </div>
                            )}
                        </div>
                    </div>

                    <div className='mb-5'>
                        <UserApprovedFilesTable />
                    </div>
                    <div className='mb-5'>
                        <UserPendingFilesTable />
                    </div>
                    <div className='mb-5'>
                        <UserPurchasedFiles />
                    </div>
                </div>
            </section>
        </>
    );
}

export default UserPage;