/* -----------------------------------------------------------------------------------------

    THSI FILE CONTAINS THE IMPORTANT WEB3/UI CONFIGURATIONS FOR THE APP

----------------------------------------------------------------------------------------- */
import React from 'react';
import { FaFighterJet, FaSadTear, FaSchool, FaBook, FaDesktop, FaTools, FaCode, FaShieldVirus, FaDatabase, FaVideo, FaGamepad, FaImages } from 'react-icons/fa';
import { RiHeart2Fill } from 'react-icons/ri';
import { BiMoviePlay } from 'react-icons/bi';
import { GiMadScientist } from 'react-icons/gi';
import { DiWindows, DiApple, DiLinux } from 'react-icons/di';

export const appSettings = {
    /* APP MAIN LOGO - REPLACE IT FROM THE PUBLIC FOLDER */
    logo: '/logo.png',

    /* APP MAIN LOGO WIDTH - CHANGE IT ACCORDING TO YOUR OWN LOGO */
    logoWidth: '190',

    /* APP BRAND NAME - USED IN ALL PAGES & META TAGE - CHANGE TO YOUR OWN BRAND NAME*/
    brandName: 'DIFI - HBIT - Digital Files Token',

    /* APP BRAND DESCRIPTION - USED IN HOME PAGE & META TAGS - PUT A SENTENCE THAT DESCRIBE YOUR APP*/
    brandDescription: 'HBIT Decentralized Digital Files Marketplace',

    /* APP AUTHOR - CHANGE IT IF YOU HAVE A LICENSE KEY OR PURCHASE CODE */
    marketplaceAuthor: 'DIFI Dev',

    /* COPYRIGHTS BRAND NAME */
    copyrightsBrand: 'DIFI.Market',

    /* TEXT IDENTIFIER FOR THE NETWORK WHERE THE APP IS UP AND RUNNING, YOU CAN WRITE WHATEVER YOU WANT */
    activeNetworkName: 'HashBit Blockchain',

    /* COPYRIGHTS LINK - REPLACE IT IF YOU HAVE A LICENSE KEY OR A PURCHASE CODE */
    copyrightsLink: 'https://difi.market',

    /* THE NETWORK RPC URL WHERE YOUR CONTRACTS ARE DEPOLYED ON, CHECK THE EXACT RPC URL FROM HERE https://chainlist.org */
    rpcUrl: 'https://rpc.hashbit.org',

    /* THE CHAIN ID of NETWORK WHERE YOUR CONTRACTS ARE DEPOLYED ON, GET IT FROM HERE https://chainlist.org */
    networkId: 11119,

    /* THE BLOCK SCAN EXPLORER WHRE YOU CAN TRACK THE TRANSACTIONS */
    blockExplorerUrls: 'https://explorer.hashbit.org/',

    /* THE  CURRENCY THAT YOUR APP WILL USE FOR TRANSACTIONS */
    currency: 'DIFI',

    /* THE NATIVE CURRENCY OF THE CHAIN THAT THE APP IS RUNNING ON */
    nativeCurrency: 'HBIT',

    /* IPFS PROJECT ID */
    IPFSProjectID: '2MdiAyEWVLi23yercgf7PPnYWRM',

    /* IPFS PROJECT SECRET */
    IPFSProjectSecret: '0cb9aa4f8c64700f3257e2fd9f047162',

    /* IPFS PROJECT ID */
    IPFSGateway: 'difi',

    /* ENDPOINT FOR ASK FOR A FEATURE FORM */
    requestFeatureFormId: 'moqzjqva',

    /* CATEGORY LIST - ADD OR DELETE FROM THEM AS YOU NEED */
    categoryOptions: [
        { label: 'Utility', value: 'Utility', icon: <FaTools size='3.5rem' className='text-primary' /> },
        { label: 'Education Materials', value: 'Education', icon: <FaSchool size='3.5rem' className='text-primary' /> },
        { label: 'eBooks', value: 'eBooks', icon: <FaBook size='3.5rem' className='text-primary' /> },
        {
            label: 'Graphic design',
            value: 'Graphic design',
            icon: <FaImages size='3.5rem' className='text-primary' />,
        },
        {
            label: 'Operating systems',
            value: 'Operating systems',
            icon: <FaDesktop size='3.5rem' className='text-primary' />,
        },
        { label: 'Programming', value: 'Programming', icon: <FaCode size='3.5rem' className='text-primary' /> },
        {
            label: 'Antivirus and security',
            value: 'Antivirus and security',
            icon: <FaShieldVirus size='3.5rem' className='text-primary' />,
        },
        { label: 'Database', value: 'Database', icon: <FaDatabase size='3.5rem' className='text-primary' /> },
        { label: 'Multimedia', value: 'Multimedia', icon: <FaVideo size='3.5rem' className='text-primary' /> },
        { label: 'Gaming', value: 'Gaming', icon: <FaGamepad size='3.5rem' className='text-primary' /> },
    ],

    /* COMPATIBILITY LIST - ADD OR DELETE FROM THEM AS YOU NEED */
    compatibilityOptions: [
        { label: 'Window', value: 'Windows', icon: <DiWindows size='3.5rem' className='text-primary' /> },
        { label: 'macOS', value: 'macOS', icon: <DiApple size='3.5rem' className='text-primary' /> },
        { label: 'Linux', value: 'Linux', icon: <DiLinux size='3.5rem' className='text-primary' /> },
    ],
};