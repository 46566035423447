import React from 'react';
import { appSettings } from '../../helpers/settings';
import { Jazzicon } from '@ukstv/jazzicon-react';
import { FiSearch } from 'react-icons/fi';
import { FiTwitter } from 'react-icons/fi';
import { FiInstagram } from 'react-icons/fi';
import { FaTelegram } from 'react-icons/fa';
function Footer() {
    return (
        <footer className='main-footer'>
            <div className='container py-3 mt-4'>
			<h2 className='h3 text-center mb-1'>We are social</h2>
			<ul className='list-inline mb-0 text-center'>
                                <li className='list-inline-item'>
                                    <a className='social-link bg-hover-primary' rel='noreferrer' href='https://twitter.com/difimarket'>
                                        <FiTwitter size='2rem' style={{ transform: 'translateY(-2px)' }} />
                                    </a>
                                </li>
                                <li className='list-inline-item'>
                                    <a className='social-link bg-hover-primary' rel='noreferrer' href='https://instagram.com/difimarket'>
                                        <FiInstagram size='2rem' style={{ transform: 'translateY(-2px)' }} />
                                    </a>
                                </li>
                                <li className='list-inline-item'>
                                    <a className='social-link bg-hover-primary' rel='noreferrer' href='https://t.me/difitoken'>
                                        <FaTelegram size='2rem' style={{ transform: 'translateY(-2px)' }} />
                                    </a>
                                </li>
                            </ul>
			
                <p className='text-muted mb-0 text-center text-sm'>
                    Created by{' '}
                    <a href={appSettings.copyrightsLink} className='text-primary'>
                        {appSettings.copyrightsBrand}
                    </a>{' '}
                    {new Date().getFullYear()}. &copy; All rights reserved
                </p>
            </div>
        </footer>
    );
}

export default Footer;
