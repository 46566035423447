import React, { useState } from 'react';
import useFiles from '../../hooks/useFiles';
import useWeb3 from '../../hooks/useWeb3';
import { toast } from 'react-toastify';
import { FaStar } from 'react-icons/fa';

function Modal() {
    const { contract, ratingModalSrc, setRatingModalState, loadFiles, setTransactionLoading } = useFiles();
    const { account } = useWeb3();

    const [rating, setRating] = useState(0);
    const [ratingReason, setRatingReason] = useState('');
    const [ratingMsg, setRatingMsg] = useState('');
    const [hover, setHover] = useState(0);
    const [submitted, setSubmitted] = useState(false);

    function handleRateFile(event) {
        event.preventDefault();
        setSubmitted(true);
        if (rating !== 0 && ratingReason !== '' && ratingMsg !== '') {
            contract.methods
                .addRate(Number(ratingModalSrc.id), ratingMsg, `${rating}`, ratingReason)
                .send({ from: account })
                .once('sending', () => {
                    setTransactionLoading(true);
                })
                .on('receipt', () => {
                    setTransactionLoading(false);
                    loadFiles(contract);
                    toast.success('Great! You have access now');
                    setRatingModalState(false);
                })
                .on('error', () => {
                    setTransactionLoading(false);
                    toast.error('Oops! Something went error');
                });
        } else {
            toast.error('Please fill the fields to be able to submit');
        }
    }

    const handleRatingChange = (value) => {
        setRating(value);
    };

    return (
        <div className='fullscreen-loader' data-aos='zoom-in-up' data-aos-duration='100'>
            <div className='fullscreen-loader-inner p-4'>
                <div className='container'>
                    <div className='row mt-4'>
                        <div className='col-xl-6 col-lg-7 mx-auto text-center'>
                            <div className='card shadow position-relative'>
                                <div className='position-absolute m-3 top-0 end-0'>
                                    <button
                                        className='btn btn-dark btn-sm z-index-20'
                                        type='button'
                                        onClick={() => setRatingModalState(false)}
                                    >
                                        Close
                                    </button>
                                </div>
                                <div className='card-body p-4 p-lg-5'>
                                    <header className='text-center mb-4'>
                                        <span className='text-muted'>Rate this File</span>
                                        <h2 className='h3'>{ratingModalSrc?.title}</h2>
                                    </header>

                                    <form onSubmit={handleRateFile} className='text-start'>
                                        <label className='form-label d-block'>Rating</label>
                                        {[...Array(5)].map((star, index) => {
                                            const value = index + 1;
                                            return (
                                                <label key={value} style={{ cursor: 'pointer' }}>
                                                    <input
                                                        type='radio'
                                                        name='rating'
                                                        className='d-none'
                                                        value={value}
                                                        checked={rating === value}
                                                        onChange={() => handleRatingChange(value)}
                                                        required
                                                    />
                                                    <FaStar
                                                        className='star me-1'
                                                        color={value <= (hover || rating) ? '#ffc107' : '#e4e5e9'}
                                                        size={17}
                                                        onMouseEnter={() => setHover(value)}
                                                        onMouseLeave={() => setHover(0)}
                                                    />
                                                </label>
                                            );
                                        })}

                                        <div className='mt-3'>
                                            <label htmlFor='ratingReason' className='form-label'>
                                                Reason
                                            </label>
                                            <input
                                                type='text'
                                                placeholder='ex: Code Quality'
                                                className={`form-control ${
                                                    submitted && ratingReason === '' ? 'is-invalid' : ''
                                                }`}
                                                id='ratingReason'
                                                value={ratingReason}
                                                onChange={(e) => setRatingReason(e.target.value)}
                                            />
                                            {submitted && ratingReason === '' && (
                                                <div className='invalid-feedback d-block'>
                                                    Please enter the rating reason
                                                </div>
                                            )}
                                        </div>

                                        <div className='mt-3'>
                                            <label htmlFor='ratingMessage' className='form-label'>
                                                Message
                                            </label>
                                            <textarea
                                                rows='4'
                                                placeholder='Enter you review message'
                                                className={`form-control ${
                                                    submitted && ratingMsg === '' ? 'is-invalid' : ''
                                                }`}
                                                id='ratingMessage'
                                                value={ratingMsg}
                                                onChange={(e) => setRatingMsg(e.target.value)}
                                            ></textarea>
                                            {submitted && ratingMsg === '' && (
                                                <div className='invalid-feedback d-block'>
                                                    Please enter the rating reason
                                                </div>
                                            )}
                                        </div>

                                        <div className='mt-3'>
                                            <button
                                                className='btn btn-primary btn-sm px-4'
                                                type='submit'
                                                disabled={rating === 0}
                                            >
                                                Submit your rating
                                            </button>
                                        </div>
                                    </form>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
}

export default Modal;
